@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.brk-map {
  position: relative;
  will-change: height;
  transition: height .5s ease-in-out;
	background-color: #fff;

	&.lazyloaded {
		&:before,
		&:after {
			opacity: 0;
			visibility: hidden;
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 96;
		opacity: 1;
		visibility: visible;
		transition-property: opacity, visibility;
		transition-duration: .4s;
		background-color: #fff;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 87px;
		height: 87px;
		background: url(../../img/preloader.gif) no-repeat center center;
		background-size: 100%;
		opacity: 1;
		visibility: visible;
		transition-property: opacity, visibility;
		transition-duration: .4s;
		z-index: 97;
	}

  &_extra {
    .brk-map__canvas {
      width: calc(100% - 570px);
      @media (max-width: 992px) {
        width: 100%;
      }
    }

  }

  &__layer-top,
  &__layer-bottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 8;
    transition: width .3s ease-in-out;
    fill: var(--white);

    .map-opened & {
      width: 0;
    }
  }

  &__layer-top {
    top: -1px;
  }

  &__layer-bottom {
    bottom: -1px;
  }

  &__opener {
    position: absolute;
    z-index: 99;
    cursor: pointer;

    &-section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9;

      &_classic {
        will-change: height;
        transition: height .3s ease .5s, background-color .3s ease;
        background-color: rgba(var(--white-rgb), 0.8);
        white-space: nowrap;

        &:hover {
          background-color: var(--white);
        }

        .map-opened & {
          height: 50px;
        }
      }

      &_round {
        height: 45px;
        transition: top .5s ease-in-out;

        h4 {
          position: absolute;
          z-index: 100;
          top: -26px;
          left: 50%;
          transform: translateX(-50%);
          letter-spacing: -0.02em;
        }

        .map-opened & {
          top: -45px;
        }
      }
    }

    &_classic {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 24px;
      overflow: hidden;
      letter-spacing: 0.02em;

      .brk-map__second {
        font-size: rem(16);
        position: relative;

        i {
          vertical-align: 0;
          font-size: rem(16);
          margin: 0 10px;

          &.fa-map-marker {
            color: var(--brand-primary);
          }
        }

        &:nth-child(1) {
          .map-opened & {
            display: none;
          }
        }

        &:nth-child(2) {
          display: none;

          .map-opened & {
            display: block;
          }
        }
      }
    }

    &_simple {
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    	width: 170px;
    	height: 46px;
    	border-radius: 23px;
      line-height: 18px;
      text-align: left;
      padding-left: 43px;
      padding-top: 12px;
      background-color: var(--brand-primary);
      box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), 0.5);
      color: var(--white);

      span {
        display: inline-block;
        border-bottom: 1px dotted var(--white);
      }

      .trigger {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%);
      	width: 38px;
      	height: 38px;
      	border-radius: 50%;
      	box-shadow: 0 -5px 16px rgba(0,0,0,.07);
        background-color: var(--white);

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          vertical-align: 0;
          font-size: rem(17);
          will-change: transform;
          transition: \transform .5s;
          color: var(--brand-primary);

          .map-opened & {
            transform: translate(-50%, -40%) rotate(180deg);
          }
        }
      }
    }

    &_round {
      top: 27px;
      left: 50%;
      transform: translateX(-50%);
    	width: 170px;
    	height: 46px;
    	border-radius: 23px;
      line-height: 46px;
      text-align: left;
      padding-left: 35px;
      text-transform: uppercase;
      transition: top .3s ease-in-out;
      background-color: var(--brand-primary);
      box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), 0.5);
      color: var(--white);

      i {
        vertical-align: 0;
        font-size: rem(16);
        margin-right: 11px;

        [dir="rtl"] & {
          margin-right: 0;
          margin-left: 11px;
        }
      }

      .map-opened & {
        top: 21px;
      }
    }
  }

  &__section {
    overflow: hidden;
    width: 100%;
    max-height: 100%;
  }

  &__canvas {
    width: 100%;
    height: 100%;
  }

  &__infoicon {
    position: absolute;
    z-index: 95;
    // transition: all .4s ease-in-out;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }

    &--text {
      position: relative;
      z-index: 3;
    }

    // Layout #1
    &_layout-one {
      top: calc(50% + 14px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    	max-width: 350px;
    	min-height: 340px;
    	box-shadow: 0 6px 30px rgba(0,0,0,.12);
      padding-top: 106px;
      background-color: #ffffff;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: var(--brand-primary);
        background-image: linear-gradient(to right, rgba(var(--brand-primary-rgb), 0.94), rgba(var(--brk-secondary-3-rgb), 0.94));
        @supports not (clip-path: polygon(50% 0%, 0% 100%, 100% 100%)) {
          background-image: none;
          background-color: rgba(0,0,0,.3);
        }
      }

      .marker {
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;

				img {
					position: relative;
					top: -48px;
				}

				i {
					font-size: rem(72);
					color: var(--brand-primary); //BASE
				}

				svg {
					width: 90px;
					height: 90px;
				}

      }

      .brk-map__infoicon--text {
        max-width: 230px;
        margin: 0 auto;
        text-align: left;
        padding-left: 30px;
        padding-top: 15px;

        [dir="rtl"] & {
          padding-left: 0;
          padding-right: 30px;
          text-align: right;
        }

        ul {
          color: var(--brand-primary);
        }

        li {
          padding: 6px 0;
        }

        a {
          display: inline-block;
          padding: 0 4px;
          letter-spacing: .02em;

          &:hover {
            color: var(--black);
          }
        }

        span {
          display: inline-block;
        }

        i {
          margin-right: 5px;
          vertical-align: 0;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 5px;
          }
        }
      }

      .btn {
      	width: 216px;
      	height: 48px;
        padding: rem(11) rem(12);
        margin: 22px 0 0;
        box-shadow: 0 5px 16px var(--brk-base-1);

        > .before {
            padding-top: 12px;
        }
      }
    }

    // Layout #2
    &_layout-two {
      width: 100%;
    	max-width: 301px;
    	min-height: 286px;
      top: calc(50% - 16px);
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 6px 30px rgba(0,0,0,.12);
      border-radius: 0 16px 0 0;
      padding-top: 45px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0 16px 0 0;
        background-color: rgba(var(--brand-primary-rgb), 0.96);
      }

      .marker {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        display: inline-block;

				i {
					vertical-align: 0;
					font-size: rem(60);
					color: $white; //BASE
				}

      }

      .brk-map__infoicon--text {
        h4 {
          margin-bottom: 27px;
          color: var(--white);
        }

        p {
          margin-bottom: 25px;
          color: rgba(var(--white-rgb), 0.8);
        }

        a {
          text-decoration: underline;
          color: rgba(var(--white-rgb), 0.8);

          &:hover {
            text-decoration: none;
            color: rgba(var(--white-rgb), 0.8);
          }
        }
      }
    }

    // Layout #3
    &_layout-three {
      width: 100%;
    	max-width: 450px;
    	min-height: 210px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    	box-shadow: 0 6px 30px rgba(0,0,0,.12);
      background-color: var(--white);

      .marker {
        position: absolute;
        top: 0;
        left: 0;
        width: 121px;
        height: 100%;
        background-color: var(--brand-primary);

        [dir="rtl"] & {
          left: auto;
          right: 0;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: -12px;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-bottom: 13px solid transparent;
          border-top: 13px solid transparent;
          border-right: 13px solid var(--brand-primary);

          [dir="rtl"] & {
            left: auto;
            right: -12px;
            border-right: 0;
            border-left: 13px solid var(--brand-primary);
          }
        }

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: rem(85);
          color: var(--white);
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 74px;
          height: 74px;

          path,
          circle,
          line,
          polyline,
          rect {
            stroke: var(--white);
          }
        }

      }

      .brk-map__infoicon--text {
        text-align: left;
        padding: 31px 10px 20px 163px;

        [dir="rtl"] & {
          text-align: right;
          padding: 31px 163px 20px 10px;
        }

        p {
          margin-bottom: 24px;
          color: #9f9f9f;
        }

        a {
          display: inline-block;
          position: relative;
          padding-left: 46px;
          color: var(--brand-primary);

          span {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          	width: 32px;
          	height: 32px;
          	border-radius: 50%;
            background-color: var(--brand-primary);
            box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), 0.5);

            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              vertical-align: 0;
              color: var(--white);
            }
          }
        }
      }
    }

    // Layout #4
    &_layout-four {
      width: 100%;
    	max-width: 324px;
    	height: auto;
      top: calc(50% + 12px);
      left: calc(50% + 15px);
      transform: translate(-50%, -50%);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translateY(-50%);
        width: 120px;
        height: 265px;
        border: 9px solid var(--brand-primary);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: -43px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-bottom: 13px solid transparent;
        border-top: 13px solid transparent;
        border-right: 13px solid var(--brand-primary);
      }

      .brk-map__infoicon--text {
        position: relative;
        width: 100%;
        min-height: 205px;
      	box-shadow: 3px 4px 39px rgba(0,0,0,.2);
        z-index: 2;
        padding: 27px 35px 15px;
        background-color: var(--brk-dark-base);

        h4,
        ul {
          color: var(--white);
        }

        li {
          padding: 5px 0;
        }

        span {
          display: inline-block;
          width: calc(100% - 35px);
          letter-spacing: 0.05em;
        }

        i {
          vertical-align: 0;
          margin-right: 15px;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    }

    // Layout #5
    &_layout-five {
      top: calc(50% - 8px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    	max-width: 300px;
    	min-height: 90px;
    	box-shadow: 0 5px 16px rgba(0,0,0,.1);
      display: flex;
      align-items: center;
      background-color: var(--white);
      border: solid 3px var(--brand-primary);

      &:before {
        content: '';
        position: absolute;
        bottom: -21px;
        left: 69px;
        width: 0;
        height: 0;
        border: 9px solid var(--brk-base-3);
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      .marker {
        position: absolute;
        top: -3px;
        left: -3px;
      	width: 90px;
      	height: 90px;
				display: flex;
				justify-content: center;
				align-items: center;
        z-index: 9;
        background: var(--brand-primary) linear-gradient(
                        to top,
                        rgba(var(--white-rgb), 0),
                        rgba(var(--white-rgb), 0.14)
        );

				i {
					font-size: rem(48);
					vertical-align: 0;
					color: var(--white); //BASE
				}

      }

      .brk-map__infoicon--text {
        height: 100%;
        padding: 10px 10px 10px 112px;

        [dir="rtl"] & {
          padding: 10px 20px 10px 90px;
        }
      }
    }

    // Layout #6
    &_layout-six {
      top: calc(50% - 9px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    	max-width: 370px;
    	min-height: 196px;
    	box-shadow: 2px 3px 30px rgba(0,0,0,.2);
      padding-top: 33px;
      background-color: var(--white);
      border: solid 2px var(--brand-primary);

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -13px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-right: 11px solid transparent;
        border-left: 11px solid transparent;
        border-top: 11px solid var(--brand-primary);
      }

      p {
        margin-bottom: 18px;
      }
    }
  }

  // Subscribe
  &__subscribe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 725px;
    clip-path: polygon(20.69% 0, 100% 0%, 100% 100%, 0% 100%);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 190px;
    padding-right: 65px;
    color: var(--white);

    [dir="rtl"] & {
      right: auto;
      left: 0;
      text-align: right;
      clip-path: polygon(0 0, 79.31% 0%, 100% 100%, 0% 100%);
      padding-right: 190px;
      padding-left: 65px;
    }

    .brk-subscribe-map {
			padding: 31px 0 35px;
      border-top: 1px solid rgba(var(--white-rgb), 0.2);
      border-bottom: 1px solid rgba(var(--white-rgb), 0.2);

      button,
		  [type="submit"] {
				top: 31px;
		  }
		}

    &--contacts {
      display: flex;
      flex-wrap: wrap;

      li {
        flex-basis: 50%;
        max-width: 50%;
        font-weight: bold;
        font-size: rem(14);
        line-height: 24px;
        padding: 5px 0;

        i {
          font-size: rem(13px);
          vertical-align: 0;
          margin-right: 11px;

          [dir="rtl"] & {
            margin-right: 0;
            margin-left: 11px;
          }
        }

        span,
        a {
          display: inline-block;
          color: var(--white);
        }
      }
    }

    @media screen and (max-width: 992px){ 
      position: relative;
      width: 100%;
      clip-path: none;
      top: 0;
      bottom: 0;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @media screen and (max-width: 500px){ 
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}